import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,

    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'carga-documentos/:acid',
        loadChildren: () => import('./components/carga-documentos/carga-documentos.module').then(m => m.CargaDocumentosModule)
      },
      {
        path: 'politica',
        loadChildren: () => import('./components/politica/politica.module').then(m => m.PoliticaModule)
      },
      {
        path: 'datos-personales/:acid',
        loadChildren: () => import('./components/datos-personales/datos-personales.module').then(m => m.DatosPersonalesModule)
      },
      {
        path: 'firma-contrato/:acid',
        loadChildren: () => import('./components/firma-contrato/firma-contrato.module').then(m => m.FirmaContratoModule)
      },
      {
        path: 'aceptacion-clausulas/:acid',
        loadChildren: () => import('./components/aceptacion-clausulas/aceptacion-clausulas.module').then(m => m.AceptacionClausulasModule)
      },
      {
        path: 'preguntas-reto/:acid',
        loadChildren: () => import('./components/preguntas-reto/preguntas-reto.module').then(m => m.PreguntasRetoModule)
      },
      {
        path: 'verificacion',
        loadChildren: () => import('./components/verificacion/verificacion.module').then(m => m.VerificacionModule)
      },
      {
        path: 'vinculacion',
        loadChildren: () => import('./components/vinculacion/vinculacion.module').then(m => m.VinculacionModule)
      },

    ]

  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
