
<app-header></app-header>
<div class="bc-container">
 
    
    <main class="">
      <router-outlet></router-outlet>
    </main>
    
  
</div>
<app-footer></app-footer>
   




