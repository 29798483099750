import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';

// Modules
import { ReactiveFormsModule } from '@angular/forms';
import { ErrorComponent } from './components/errores/error.component';
import { BancolombiaDesignModule } from '../bancolombia-design/bancolombia-design.module';
import { LoaderComponent } from './components/loader/loader.component';
import {BcInputDateModule} from '@bancolombia/design-system-web/bc-input-date'
import { FormsModule } from '@angular/forms'
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ErrorComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    BancolombiaDesignModule,
    BcInputDateModule,
    FormsModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ReactiveFormsModule,
    ErrorComponent,
    BancolombiaDesignModule,
    LoaderComponent,
    BcInputDateModule,
    FormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
