import { Component, Input } from '@angular/core';
import { BcHeaderNavItem } from '@bancolombia/design-system-web/bc-header';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  @Input() objectSpread: any;


  ariaLabelogo='Bancolombia';
  ariaLabelChannelName='Nombre largo del canal de transacciones';

  companyList: BcHeaderNavItem[] = [
    { icon: 'store', text: 'Company 1', subText: 'Nit: 1111111-1' },
    { icon: 'store', text: 'Company 2', subText: 'Nit: 2222222-2' },
    { icon: 'store', text: 'Company 3', subText: 'Nit: 3333333-3' },
  ];
  languages = [
    { text: 'Español' },
    { text: 'Inglés' },
  ];

  configTooltip = {
    isTooltip: true,
    textTooltip: 'Notificaciones',
    positionTooltip: 'bottom',
  };

  configTooltip1 = {
    isTooltip: true,
    textTooltip: 'Herramientas',
    positionTooltip: 'bottom',
  };

  firstOptionsMobile = [
    {id: '01', mainText: 'Company 1', iconLeft: 'store'},
    {id: '02', mainText: 'Company 2', iconLeft: 'store'}
  ]

  firstOptions1 = [
    {id: '01', mainText: 'Company 1', iconLeft: 'store'},
    {id: '02', mainText: 'Company 2', iconLeft: 'store'}
  ]
}
