import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BcHeaderModule } from '@bancolombia/design-system-web/bc-header';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcFooterModule } from '@bancolombia/design-system-web/bc-footer';
import { BcMenuModule } from '@bancolombia/design-system-web/bc-menu';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button'
import { BcBtnGroupModule } from '@bancolombia/design-system-web/bc-button-group';
import { BcStepperModule } from '@bancolombia/design-system-web/bc-stepper';
import { BcInputFileModule } from '@bancolombia/design-system-web/bc-input-file';
import { BcCardSelectModule } from '@bancolombia/design-system-web/bc-card-select';
import { BcSwitchModule } from '@bancolombia/design-system-web/bc-switch';
import { BcLinkModule } from '@bancolombia/design-system-web/bc-link';
import { BcCheckboxModule } from '@bancolombia/design-system-web/bc-checkbox';
import { BcAccordionModule } from '@bancolombia/design-system-web/bc-accordion';
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';
import { BcRadioModule } from '@bancolombia/design-system-web/bc-radio';
import { BcInputTokenModule } from '@bancolombia/design-system-web/bc-input-token';
import { BcModalModule } from '@bancolombia/design-system-web/bc-modal';
import { BcAlertModule } from '@bancolombia/design-system-web/bc-alert';
import { BcLoaderModule } from '@bancolombia/design-system-web/bc-loader';
import { BcTooltipModule } from '@bancolombia/design-system-web/bc-tooltip';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BcHeaderModule,
    BcIconModule.forRoot(),
    BcFooterModule,
    BcMenuModule,
    BcBtnGroupModule,
    BcStepperModule,
    BcInputFileModule,
    BcCardSelectModule,
    BcSwitchModule,
    BcLinkModule,
    BcCheckboxModule,
    BcAccordionModule,
    BcInputSelectModule,
    BcRadioModule,
    BcInputTokenModule,
    BcModalModule,
    BcAlertModule,
    BcLoaderModule,
    BcTooltipModule,
  ],
  exports: [
    BcHeaderModule,
    BcIconModule,
    BcFooterModule,
    BcMenuModule,
    BcInputModule,
    BcButtonModule,
    BcBtnGroupModule,
    BcStepperModule,
    BcInputFileModule,
    BcCardSelectModule,
    BcSwitchModule,
    BcLinkModule,
    BcCheckboxModule,
    BcAccordionModule,
    BcInputSelectModule,
    BcRadioModule,
    BcInputTokenModule,
    BcModalModule,
    BcAlertModule,
    BcLoaderModule,
    BcTooltipModule,
  ]
})
export class BancolombiaDesignModule { }
