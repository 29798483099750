import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  messageIp: string;
  messageLastConnection: any;
  footer1: any;
  @Input() objectSpread = {
    ip: "",
    lastConection: ""
  };

  constructor() {
    this.messageIp = ''
    this.messageLastConnection = ''
   }
  ngOnInit(): void {
    this.messageIp = `Dirección IP ${this.objectSpread.ip}`;
    this.messageLastConnection = this.capitalizeFirst(this.objectSpread.lastConection);
    this.footer1 = {
      topTextContent: [
        {
          title: { text: ' ' },
          texts: [{ text: ' ' }]
        }
      ],
      topLinks: {
        alignContent: 'flex-end',
      },
      bottomTextRighContent: [this.messageIp, this.messageLastConnection],
      bottomTextLeftContent: ['Copyright © 2022 Grupo Bancolombia.'],
      socialNetBottomRight: [
        { name:'facebook', link:'https://www.facebook.com/bancolombia' },
        { name:'twitter', link:'https://twitter.com/Bancolombia' },
        { name:'linkedin', link:'https://www.linkedin.com/company/22690' },
        { name:'instagram', link:'https://instagram.com/bancolombia' },
        { name:'youtube', link:'https://www.youtube.com/user/GrupoBancolombia' }
      ]
    }
  }

  capitalizeFirst(f: string) {
    return f.charAt(0).toUpperCase() + f.slice(1);
  }

}
