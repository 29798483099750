// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'certificacion',
  production: false,
  apiUrl: 'https://leasing-ext-qa.apps.ambientesbc.com/vinculacion-leasing/vl/api/leasing/',
  Ip: '127.0.0.0',
  token: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJtYWNybyIsInJvbGUiOlsiQ2xpZW50ZSIsIlByb3ZlZWRvciIsIkZpcm1hIl0sImV4cCI6MTY0NzExNDAwMDAwMCwiaWF0IjoxNjM5NTk2MzQ5NTAyLCJ1c2VyIjp7Imxhc3ROYW1lIjoiWmFwYXRhIiwidHlwZVBlcnNvbiI6Im5pdCIsIm5hbWUiOiJBbmRyZXMiLCJuaXQiOiI5MDAwNTQ1NDU0IiwiaWRlbnRpZmljYXRpb25OdW1iZXIiOiIxMTExMTEifSwianRpIjoiMTE2ZTFlNDEtNDc0My00YzZkLWI3OGItYmZlN2QyNzU2ZWYzIiwiY29kRXJyb3IiOjB9.o8NBUfloAybzI3CLXRzMpnbcWFKS1_-lif5wUlGCOCt7F7u6TcEqFqj7ErNuA1wBduIbPIOv5szpt5ARk-JMmkFpI2eQiDuy5_nOpYSTmRgWOJxuRw8YzJLSejV2lV2UL6cBUd_Sh85A0DgmUbD8PEIyhFv1xcOygb2sIzByUy0HbvwSrv_o55axwzAnGLfglm6q47bj0kGXjYF1V_dzpRYxKsxchzCI3JMYVObpoxmyrpi_qVRO8kOzzykqFcp21h7OvQ3K35VDzx_vrPbXSrYc0rf3D2M9Y62DnKc6Iy6t8eCPnijXaAXz4PTaQ91mYNnSGh35MMS3kwF_iBlpuA',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
